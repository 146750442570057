import { Link } from "gatsby"
import React from "react"
import "./Footer.less"
import PageWidth from "../PageWidth/PageWidth"
import Section from "../Section/Section"
import SmartLink from "../../atoms/SmartLink/SmartLink"

const Footer = () => {
  return (
    <div className="footer">
      <Section className="footer__info">
        <PageWidth>
          <ol className="footer__list">
            <li className="footer__item">
              <SmartLink
                inNewWindow={true}
                to="https://www.prooftimetracking.com/imprint"
              >
                Imprint
              </SmartLink>
            </li>
            <li className="footer__item">
              <SmartLink
                inNewWindow={true}
                to="https://www.prooftimetracking.com/privacy-policy"
              >
                Privacy Policy
              </SmartLink>
            </li>
          </ol>
        </PageWidth>
      </Section>
    </div>
  )
}

export default Footer
