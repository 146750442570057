import React from "react"
import "./Header.less"
import PageWidth from "../PageWidth/PageWidth"
import Section from "../Section/Section"
import SmartLink from "../../atoms/SmartLink/SmartLink"
import Image from "../../atoms/Image/Image"

const Navigation = () => {
  return (
    <Section className="header">
      <PageWidth>
        <SmartLink
          className="header__link"
          to="https://www.prooftimetracking.com/"
        >
          <Image
            src="favicon"
            title="Watch for this icon"
            className="header__logo"
          />
          proof
        </SmartLink>
      </PageWidth>
    </Section>
  )
}

export default Navigation
