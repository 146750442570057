import React from "react";
import PropTypes from "prop-types";
import "./Section.less";

const Section = ({ children, background, className = "" }) => {
  let classNames = ["section", className];
  switch (background) {
    case "black":
      classNames.push("section--black");
      break;
    case "gray":
      classNames.push("section--gray");
      break;
    case "yellow":
      classNames.push("section--yellow");
      break;
    default:
      break;
  }

  return <section className={classNames.join(" ")}>{children}</section>;
};

Section.propTypes = {
  className: PropTypes.string,
  background: PropTypes.oneOf(["black", "gray"])
};

Section.defaultProps = {};

export default Section;
