import React from "react"
import "./SmartLink.less"

interface Props {
  to: string
  className?: string
  punctuation?: string
  inNewWindow?: boolean
  children?: React.ReactNode
}

const SmartLink = (props: Props) => {
  const { children, className, to, punctuation, inNewWindow, ...rest } = props

  const target = inNewWindow
    ? {
        target: "_blank",
        rel: "norefferer noopener",
      }
    : {}
  const link = (
    <a href={to} {...target} {...rest}>
      {children}
    </a>
  )

  const classNames = ["smart-link"]
  if (className) {
    classNames.push(className)
  }
  return (
    <>
      {" "}
      <span className={classNames.join(" ")}>
        {link}
        {punctuation}
      </span>{" "}
    </>
  )
}

SmartLink.defaultProps = {
  punctuation: "",
  inNewWindow: false,
}

export default SmartLink
