import React from "react";
import "./PageWidth.less";
import PropTypes from "prop-types";

const PageWidth = ({ children, isContent, className = "" }) => {
  let classNames = ["page-width", className];
  if (isContent) {
    classNames.push("page-width--homepage");
  }

  return <div className={classNames.join(" ")}>{children}</div>;
};

PageWidth.defaultProps = {
  className: PropTypes.toString,
  isContent: false
};

export default PageWidth;
